.App {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #3b4551;
  text-align: center;
}

@keyframes fadeInExpandAnimation {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.logo-animate {
  animation: fadeInExpandAnimation 1s ease forwards;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

/* Add mobile query for .app-logo */
@media (max-width: 768px) {
  .App-logo {
    height: 25vmin;
  }
}

.App-header {
  background-color: #acbcb3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #3b4551;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 1.2em;
  line-height: 1.2;
}

/* add a media query for h1 */
@media (max-width: 768px) {
  h1 {
    font-size: 1.5em;
  }
}

.blurb {
  width: 60%;
}

button {
  background-color: #f1d073;
  color: #3b4551;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 0;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  transition: all .3s ease;
}

button:hover {
  background-color: #d2ccbc;
  color: #3b4551;
  transition: all .3s ease;
}

input {
  border: none;
  border-radius: 3px;
  font-style: normal;
  margin-right: 10px;
  padding: 12px 24px;
  text-decoration: none;
  transition: all .3s ease;
  width: 150px;
}

footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #acbcb3;
  text-align: center;
  color: #3b4551;
}
